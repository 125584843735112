.flex-container {
  display: flex;
  justify-content: center;
}

.flex-container>div {
  background-color: #f1f1f1;
  /* width: 100px; */
  margin: 5px;
  text-align: center;
  line-height: 50px;
  /* font-size: 30px; */
}

.text {
  align-self: center;
  text-align: center;
}

.start-screen.active {
  background-color: #f1f1f1;
  opacity: 1;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10%;
  left: 5%;
  padding: 10px;
  transition: 850ms;
  z-index: 10;
  filter: drop-shadow(0 0 10px rgb(109, 109, 109));
}

.start-screen {
  display: flex;
  position: absolute;
  height: 0%;
  width: 0%;
  transition: 850ms;
  left: -100%;
}

.button-clue {
  display: inline-block;
  position: relative;
  /* z-index: 13; */
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 8px;
  overflow: auto;
  background-color: rgb(255, 118, 6);
  font-size: xx-large;
}

.button {
  display: inline-block;
  position: relative;
  /* z-index: 13; */
  padding: 15px;
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 8px;
  overflow: auto;
  background-color: rgb(180, 180, 180);
}

.score {
  display: flex;
  justify-content: flex-start;
  margin: 10px 10px 10px 10px;
  font-weight: bold;
}

.play-box {
  margin-top: -30px;
}

#field {
  display: flex;
  justify-content: center;
  width: 500px;
  height: 300px;
  background-color: lightgray;
  border: 4px, solid;
  position: relative;
}

#ball {
  /* width: 9px;
    height: 9px; */
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 30px;
  top: 250px;
  z-index: 15;
}

#rocket {
  width: 9px;
  height: 70px;
  background-color: rgb(129, 129, 129);
  position: absolute;
  left: 110px;
  top: 300px;
  z-index: 9;
}